import React from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import Layout from '../../components/layout';
import HowToUse from '../../components/HowToUse';
import Link from '../../components/Link';

const meta = {
  en: {
    title: 'Use Markdown in Evernote - EverTool',
    subtitle: 'The best Evernote markdown in 2020. Improve your daily Evernote productivity',
  },
  'zh-tw': {
    title: '在 Evernote 使用 Markdown - EverTool',
    subtitle: '2020 年最好用的 Evernote markdown。提昇你的 Evernote 效率',
  },
  'zh-cn': {
    title: '在印象笔记使用 Markdown - EverTool',
    subtitle: '2020 年最好用的印象笔记 Markdown。提升你的印象笔记效率',
  },
  ja: {
    title: 'Evernoteの中に使用マークダウン - EverTool',
    subtitle: '2020年で最高のEvernoteの値下げは、あなたの毎日のEvernoteの生産性を向上します',
  },
};

const KnowMoreMarkdown = () => (
  <div>
    <p><FormattedMessage id="markdown.tired" /></p>
    <p className="is-size-5">
      <Link to="/how-to-use/markdown-themes/">
        <FormattedMessage id="markdown.themes" />
      </Link>
    </p>
  </div>
);

class UseMarkdownInEvernote extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const config = {
      images: {
        mac: {
          intro1: [data.mac_raw],
          intro2: [data.mac_cut, data.mac_evertool],
          intro3: [data.mac_paste],
        },
      },
      introId: {
        title: 'markdown.title',
        step1: 'markdown.step1',
        step2: 'markdown.step2',
        step3: 'markdown.step3',
      },
    };
    this.state = {
      config,
    };
  }

  render() {
    const { pageContext } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;
    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
        }}
      >
        <div className="how-to-wrapper">
          <HowToUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
            oneSystem="mac"
            imageStyle={{
              step1: '300px',
              step2: '300px',
              step3: '400px',
            }}
          >
            <hr />
            <KnowMoreMarkdown />
          </HowToUse>
        </div>
      </Layout>
    );
  }
}

export default UseMarkdownInEvernote;

export const pageQuery = graphql`
  query UseMarkdownInEvernoteQuery {
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
    mac_cut: file(relativePath: { eq: "how-to/use-markdown/mac/cut.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_raw: file(relativePath: { eq: "how-to/use-markdown/mac/raw.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_paste: file(relativePath: { eq: "how-to/use-markdown/mac/paste.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_evertool: file(relativePath: { eq: "how-to/use-markdown/mac/evertool.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
